// src/main/webapp/app/modules/tableau-de-bord/TempsCycleCommandeChart.tsx
import React from 'react';
import { Chart, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Title } from 'chart.js';
import { Line } from 'react-chartjs-2';

// Enregistrer les éléments nécessaires pour le graphique en courbes
Chart.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Title);

const TempsCycleCommandeChart = () => {
  const data = {
    labels: Array.from({ length: 30 }, (_, i) => `Commande ${i + 1}`), // Générer 30 commandes
    datasets: [
      {
        label: 'Temps de Cycle de Commande (en jours)',
        data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 10) + 5), // Temps de cycle entre 5 et 15 jours
        fill: false,
        borderColor: '#4BC0C0',
        tension: 0.1,
      },
    ],
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <Line data={data} />
    </div>
  );
};

export default TempsCycleCommandeChart;
