import dayjs from 'dayjs';
import { IProduit } from 'app/shared/model/produit.model';

export interface IPhoto {
  id?: number;
  intitule?: string;
  lienVersFichierPhoto?: string;
  description?: string | null;
  author?: string | null;
  proprietaire?: string | null;
  hauteur?: number | null;
  largeur?: number | null;
  datePersistence?: dayjs.Dayjs;
  dateUpdate?: dayjs.Dayjs | null;
  produit?: IProduit | null;
}

export const defaultValue: Readonly<IPhoto> = {};
