// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ==========================================================================
Main page styles
========================================================================== */
.hipster {
  display: inline-block;
  width: 100%;
  height: 497px;
  background: url("/content/images/your-logo-here.jpg") no-repeat center top;
  background-size: contain;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/home/home.scss"],"names":[],"mappings":"AAAA;;4EAAA;AAGA;EACE,qBAAA;EACA,WAAA;EACA,aAAA;EAEA,0EAAA;EACA,wBAAA;AAAF","sourcesContent":["/* ==========================================================================\r\nMain page styles\r\n========================================================================== */\r\n.hipster {\r\n  display: inline-block;\r\n  width: 100%;\r\n  height: 497px;\r\n  //background: url('/content/images/jhipster_family_member_0.svg') no-repeat center top;\r\n  background: url('/content/images/your-logo-here.jpg') no-repeat center top;\r\n  background-size: contain;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
