import dayjs from 'dayjs';
import { IClient } from 'app/shared/model/client.model';

export interface IDevis {
  id?: number;
  montantTotal?: number;
  prixDeTransport?: number;
  isPayedIsFacture?: boolean;
  lienVersDevisPdf?: string | null;
  datePersistence?: dayjs.Dayjs;
  dateUpdate?: dayjs.Dayjs | null;
  client?: IClient | null;
}

export const defaultValue: Readonly<IDevis> = {
  isPayedIsFacture: false,
};
