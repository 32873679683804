import dayjs from 'dayjs';
import { IPartenaire } from 'app/shared/model/partenaire.model';
import { IDevis } from 'app/shared/model/devis.model';

export interface IConteneur {
  id?: number;
  prixDeTransit?: number;
  destination?: string;
  poids?: number;
  datePersistence?: dayjs.Dayjs;
  dateUpdate?: dayjs.Dayjs | null;
  partenaire?: IPartenaire | null;
  devis?: IDevis | null;
}

export const defaultValue: Readonly<IConteneur> = {};
