// src/main/webapp/app/modules/tableau-de-bord/TauxLivraisonTempsChart.tsx
import React from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// Enregistrer les éléments nécessaires pour le graphique en camembert
Chart.register(ArcElement, Tooltip, Legend);

const TauxLivraisonTempsChart = () => {
  const data = {
    labels: ['Livraisons à temps', 'Livraisons en retard'],
    datasets: [
      {
        label: 'Taux de Livraison à Temps',
        data: [75, 25], // Exemple de données fictives
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return <Pie data={data} />;
};

export default TauxLivraisonTempsChart;
