// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0;
  vertical-align: 2px;
}

[dir="ltr"] ul#strength {
  margin-left: 15px;
}

[dir="rtl"] ul#strength {
  margin-right: 15px;
}

.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  width: 20px;
}

[dir="ltr"] .point {
  margin-right: 1px;
}

[dir="rtl"] .point {
  margin-left: 1px;
}

.point:last-child {
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/password/password-strength-bar.scss"],"names":[],"mappings":"AAAA;;4EAAA;AAGA;EACE,eAAA;EACA,gBAAA;EACA,SAAA;EAEA,UAAA;EACA,mBAAA;AACF;;AAPA;EAIE,iBAAA;AAGF;;AAPA;EAIE,kBAAA;AAGF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;EAEA,WAAA;AACF;;AAPA;EAKE,iBAAA;AAEF;;AAPA;EAKE,gBAAA;AAEF;;AAAE;EACE,oBAAA;AAEJ","sourcesContent":["/* ==========================================================================\r\nstart Password strength bar style\r\n========================================================================== */\r\nul#strength {\r\n  display: inline;\r\n  list-style: none;\r\n  margin: 0;\r\n  margin-left: 15px;\r\n  padding: 0;\r\n  vertical-align: 2px;\r\n}\r\n\r\n.point {\r\n  background: #ddd;\r\n  border-radius: 2px;\r\n  display: inline-block;\r\n  height: 5px;\r\n  margin-right: 1px;\r\n  width: 20px;\r\n  &:last-child {\r\n    margin: 0 !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
