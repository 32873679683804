import React from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';

// Register the necessary components and the datalabels plugin
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, ChartDataLabels);

const Finances: React.FC = () => {
  // Fake data for the line chart (Chiffre d'affaire)
  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Chiffre d’Affaires',
        data: [12000, 15000, 13000, 17000, 21000, 19000, 23000],
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
      {
        label: 'Coût de Revient',
        data: [8000, 9000, 8500, 10000, 12000, 11000, 14000],
        fill: false,
        borderColor: 'rgba(255,99,132,1)',
        tension: 0.1,
      },
    ],
  };

  // Calculate fake data for margins
  const profit = lineData.datasets[0].data.map((value, index) => value - lineData.datasets[1].data[index]);
  const grossMargin = profit.map((value, index) => ((value / lineData.datasets[0].data[index]) * 100).toFixed(2));
  const netMargin = profit.map((value, index) => ((value / lineData.datasets[0].data[index]) * 100).toFixed(2));

  // Fake data for pie chart (Répartition des ventes des produits)
  const pieData = {
    labels: ['Produit A', 'Produit B', 'Produit C', 'Produit D'],
    datasets: [
      {
        data: [300, 50, 100, 40],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  const pieOptions = {
    plugins: {
      datalabels: {
        color: '#fff',
        font: {
          size: 14,
        },
        formatter: (value: number) => `${value}`, // Display the actual value
      },
    },
  };

  // Fake data for cash flow
  const cashFlow = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Trésorerie',
        data: [5000, 7000, 6000, 8000, 9000, 8500, 9500],
        fill: false,
        borderColor: 'rgba(255,205,86,1)',
        tension: 0.1,
      },
    ],
  };

  return (
    <div style={styles.container}>
      {/* Comment Header */}
      <div style={styles.commentHeader}>
        Ces valeurs sont pour l'instant fake en attendant les données réelles en provenance de la base de données. Nous avons juste préparé
        ici les éléments nécessaires prêts à être reliés aux vraies données.
      </div>

      <div style={styles.chartContainer}>
        <h3>Évolution du Chiffre d'Affaire</h3>
        <Line data={lineData} />
      </div>

      <div style={styles.chartContainer}>
        <h3>Répartition des Ventes des Produits</h3>
        <Pie data={pieData} options={pieOptions} />
      </div>

      <div style={styles.chartContainer}>
        <h3>Trésorerie</h3>
        <Line data={cashFlow} />
      </div>

      <div style={styles.chartContainer}>
        <h3>Marge Brute (%)</h3>
        <Line
          data={{
            labels: lineData.labels,
            datasets: [
              {
                label: 'Marge Brute',
                data: grossMargin,
                fill: false,
                borderColor: 'rgba(54, 162, 235, 1)',
                tension: 0.1,
              },
            ],
          }}
        />
      </div>

      <div style={styles.chartContainer}>
        <h3>Marge Nette (%)</h3>
        <Line
          data={{
            labels: lineData.labels,
            datasets: [
              {
                label: 'Marge Nette',
                data: netMargin,
                fill: false,
                borderColor: 'rgba(255, 99, 132, 1)',
                tension: 0.1,
              },
            ],
          }}
        />
      </div>

      <div style={styles.mapContainer}>
        <h3>Chiffre d'Affaires par Localisation Client</h3>

        <MapContainer className="map-container" center={[46.6034, 1.8883]} zoom={5} scrollWheelZoom={false}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
          />
          <Marker position={[48.8566, 2.3522]}>
            <Popup>Chiffre d'affaires: 12000€ à Paris</Popup>
          </Marker>
          <Marker position={[45.764, 4.8357]}>
            <Popup>Chiffre d'affaires: 8000€ à Lyon</Popup>
          </Marker>
          <Marker position={[43.6108, 3.8767]}>
            <Popup>Chiffre d'affaires: 5000€ à Montpellier</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    padding: '20px',
  } as React.CSSProperties,
  chartContainer: {
    flex: '1 1 45%',
    minWidth: '300px',
    marginBottom: '20px',
  } as React.CSSProperties,
  commentHeader: {
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: '#ffcccc',
    borderRadius: '5px',
    color: '#333',
    fontSize: '14px',
    textAlign: 'center',
  } as React.CSSProperties,
  mapContainer: {
    flex: '1 1 100%', // Full width for the map
    marginBottom: '20px',
  } as React.CSSProperties,
};

export default Finances;
