import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LigneProduit from './ligne-produit';
import LigneProduitDetail from './ligne-produit-detail';
import LigneProduitUpdate from './ligne-produit-update';
import LigneProduitDeleteDialog from './ligne-produit-delete-dialog';

const LigneProduitRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LigneProduit />} />
    <Route path="new" element={<LigneProduitUpdate />} />
    <Route path=":id">
      <Route index element={<LigneProduitDetail />} />
      <Route path="edit" element={<LigneProduitUpdate />} />
      <Route path="delete" element={<LigneProduitDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LigneProduitRoutes;
