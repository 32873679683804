import dayjs from 'dayjs';

export interface IPartenaire {
  id?: number;
  nomOuRaisonSociale?: string;
  tarifDeTransport?: number;
  datePersistence?: dayjs.Dayjs;
  dateUpdate?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IPartenaire> = {};
