import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './ligne-produit.reducer';

export const LigneProduitDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const ligneProduitEntity = useAppSelector(state => state.ligneProduit.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="ligneProduitDetailsHeading">
          <Translate contentKey="alexisExportApp.ligneProduit.detail.title">LigneProduit</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{ligneProduitEntity.id}</dd>
          <dt>
            <span id="quantiteCommandee">
              <Translate contentKey="alexisExportApp.ligneProduit.quantiteCommandee">Quantite Commandee</Translate>
            </span>
          </dt>
          <dd>{ligneProduitEntity.quantiteCommandee}</dd>
          <dt>
            <span id="datePersistence">
              <Translate contentKey="alexisExportApp.ligneProduit.datePersistence">Date Persistence</Translate>
            </span>
          </dt>
          <dd>
            {ligneProduitEntity.datePersistence ? (
              <TextFormat value={ligneProduitEntity.datePersistence} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="dateUpdate">
              <Translate contentKey="alexisExportApp.ligneProduit.dateUpdate">Date Update</Translate>
            </span>
          </dt>
          <dd>
            {ligneProduitEntity.dateUpdate ? (
              <TextFormat value={ligneProduitEntity.dateUpdate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alexisExportApp.ligneProduit.produit">Produit</Translate>
          </dt>
          <dd>{ligneProduitEntity.produit ? ligneProduitEntity.produit.id : ''}</dd>
          <dt>
            <Translate contentKey="alexisExportApp.ligneProduit.devis">Devis</Translate>
          </dt>
          <dd>{ligneProduitEntity.devis ? ligneProduitEntity.devis.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/ligne-produit" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ligne-produit/${ligneProduitEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LigneProduitDetail;
