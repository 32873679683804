import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './produit.reducer';

export const ProduitDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const produitEntity = useAppSelector(state => state.produit.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="produitDetailsHeading">
          <Translate contentKey="alexisExportApp.produit.detail.title">Produit</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{produitEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alexisExportApp.produit.name">Name</Translate>
            </span>
          </dt>
          <dd>{produitEntity.name}</dd>
          <dt>
            <span id="provenance">
              <Translate contentKey="alexisExportApp.produit.provenance">Provenance</Translate>
            </span>
          </dt>
          <dd>{produitEntity.provenance}</dd>
          <dt>
            <span id="poids">
              <Translate contentKey="alexisExportApp.produit.poids">Poids</Translate>
            </span>
          </dt>
          <dd>{produitEntity.poids}</dd>
          <dt>
            <span id="priceDeVente">
              <Translate contentKey="alexisExportApp.produit.priceDeVente">Price De Vente</Translate>
            </span>
          </dt>
          <dd>{produitEntity.priceDeVente}</dd>
          <dt>
            <span id="prixInitial">
              <Translate contentKey="alexisExportApp.produit.prixInitial">Prix Initial</Translate>
            </span>
          </dt>
          <dd>{produitEntity.prixInitial}</dd>
          <dt>
            <span id="sachetDeProtection">
              <Translate contentKey="alexisExportApp.produit.sachetDeProtection">Sachet De Protection</Translate>
            </span>
          </dt>
          <dd>{produitEntity.sachetDeProtection}</dd>
          <dt>
            <span id="dateDExpedition">
              <Translate contentKey="alexisExportApp.produit.dateDExpedition">Date D Expedition</Translate>
            </span>
          </dt>
          <dd>
            {produitEntity.dateDExpedition ? (
              <TextFormat value={produitEntity.dateDExpedition} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="dateDeReception">
              <Translate contentKey="alexisExportApp.produit.dateDeReception">Date De Reception</Translate>
            </span>
          </dt>
          <dd>
            {produitEntity.dateDeReception ? (
              <TextFormat value={produitEntity.dateDeReception} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lienVersFichierPhoto">
              <Translate contentKey="alexisExportApp.produit.lienVersFichierPhoto">Lien Vers Fichier Photo</Translate>
            </span>
          </dt>
          <dd>{produitEntity.lienVersFichierPhoto}</dd>
          <dt>
            <span id="datePersistence">
              <Translate contentKey="alexisExportApp.produit.datePersistence">Date Persistence</Translate>
            </span>
          </dt>
          <dd>
            {produitEntity.datePersistence ? (
              <TextFormat value={produitEntity.datePersistence} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="dateUpdate">
              <Translate contentKey="alexisExportApp.produit.dateUpdate">Date Update</Translate>
            </span>
          </dt>
          <dd>{produitEntity.dateUpdate ? <TextFormat value={produitEntity.dateUpdate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/produit" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/produit/${produitEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProduitDetail;
