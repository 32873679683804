import React from 'react';
import { Translate, translate } from 'react-jhipster';
import MenuItem from 'app/shared/layout/menus/menu-item';

const TableauDeBordMenu = ({ onClick }) => (
  <>
    <MenuItem icon="chart-line" to="/finances" onClick={onClick}>
      <Translate contentKey="global.menu.tableaudeBord.finances" />
    </MenuItem>
    <MenuItem icon="check-circle" to="/qualite" onClick={onClick}>
      <Translate contentKey="global.menu.tableaudeBord.qualite" />
    </MenuItem>
    <MenuItem icon="truck" to="/logistique" onClick={onClick}>
      <Translate contentKey="global.menu.tableaudeBord.logistique" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </>
);

export default TableauDeBordMenu;
