// src/main/webapp/app/modules/tableau-de-bord/CoutLogistiqueUniteChart.tsx
import React from 'react';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Enregistrement des éléments requis pour le graphique en barres
Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Title);

const CoutLogistiqueUniteChart = () => {
  const data = {
    labels: ['Janvier', 'Février', 'Mars'], // Mois fictifs
    datasets: [
      {
        label: 'Coût Logistique par Unité Expédiée (€)',
        data: [12, 14, 13], // Données fictives
        backgroundColor: '#42A5F5',
      },
    ],
  };

  return <Bar data={data} />;
};

export default CoutLogistiqueUniteChart;
