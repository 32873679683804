import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Produit A', cout: 2000, quantite: 50 }, // Coût de revient = 2000, Quantité perdue = 50
  { name: 'Produit B', cout: 1500, quantite: 30 },
  { name: 'Produit C', cout: 2500, quantite: 70 },
];

const coutTotal = data.map(item => ({ ...item, coutTotal: item.cout * item.quantite }));

const CoutNonQualiteChart = () => (
  <BarChart width={500} height={300} data={coutTotal} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="coutTotal" fill="#8884d8" />
  </BarChart>
);

export default CoutNonQualiteChart;
