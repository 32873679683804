import dayjs from 'dayjs';
import { IProduit } from 'app/shared/model/produit.model';
import { IDevis } from 'app/shared/model/devis.model';

export interface ILigneProduit {
  id?: number;
  quantiteCommandee?: string;
  datePersistence?: dayjs.Dayjs;
  dateUpdate?: dayjs.Dayjs | null;
  produit?: IProduit | null;
  devis?: IDevis | null;
}

export const defaultValue: Readonly<ILigneProduit> = {};
