import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Défectueux', value: 10 }, // 10 produits défectueux
  { name: 'Non défectueux', value: 90 }, // 90 produits non défectueux
];

const COLORS = ['#FF8042', '#0088FE'];

const TauxDefautChart = () => (
  <PieChart width={400} height={400}>
    <Pie data={data} cx={200} cy={200} innerRadius={60} outerRadius={80} fill="#8884d8" paddingAngle={5} dataKey="value">
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
    </Pie>
    <Tooltip />
    <Legend />
  </PieChart>
);

export default TauxDefautChart;
