import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Conteneur from './conteneur';
import ConteneurDetail from './conteneur-detail';
import ConteneurUpdate from './conteneur-update';
import ConteneurDeleteDialog from './conteneur-delete-dialog';

const ConteneurRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Conteneur />} />
    <Route path="new" element={<ConteneurUpdate />} />
    <Route path=":id">
      <Route index element={<ConteneurDetail />} />
      <Route path="edit" element={<ConteneurUpdate />} />
      <Route path="delete" element={<ConteneurDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ConteneurRoutes;
