import dayjs from 'dayjs';
import { IRebut } from 'app/shared/model/rebut.model';

export interface IClient {
  id?: number;
  nom?: string;
  prenom?: string;
  raisonSociale?: string | null;
  email?: string | null;
  phone?: string;
  addresseDeLivraison?: string | null;
  codePostale?: string | null;
  pays?: string;
  datePersistence?: dayjs.Dayjs;
  dateUpdate?: dayjs.Dayjs | null;
  rebuts?: IRebut | null;
}

export const defaultValue: Readonly<IClient> = {};
