import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './conteneur.reducer';

export const ConteneurDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const conteneurEntity = useAppSelector(state => state.conteneur.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="conteneurDetailsHeading">
          <Translate contentKey="alexisExportApp.conteneur.detail.title">Conteneur</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{conteneurEntity.id}</dd>
          <dt>
            <span id="prixDeTransit">
              <Translate contentKey="alexisExportApp.conteneur.prixDeTransit">Prix De Transit</Translate>
            </span>
          </dt>
          <dd>{conteneurEntity.prixDeTransit}</dd>
          <dt>
            <span id="destination">
              <Translate contentKey="alexisExportApp.conteneur.destination">Destination</Translate>
            </span>
          </dt>
          <dd>{conteneurEntity.destination}</dd>
          <dt>
            <span id="poids">
              <Translate contentKey="alexisExportApp.conteneur.poids">Poids</Translate>
            </span>
          </dt>
          <dd>{conteneurEntity.poids}</dd>
          <dt>
            <span id="datePersistence">
              <Translate contentKey="alexisExportApp.conteneur.datePersistence">Date Persistence</Translate>
            </span>
          </dt>
          <dd>
            {conteneurEntity.datePersistence ? (
              <TextFormat value={conteneurEntity.datePersistence} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="dateUpdate">
              <Translate contentKey="alexisExportApp.conteneur.dateUpdate">Date Update</Translate>
            </span>
          </dt>
          <dd>
            {conteneurEntity.dateUpdate ? <TextFormat value={conteneurEntity.dateUpdate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alexisExportApp.conteneur.partenaire">Partenaire</Translate>
          </dt>
          <dd>{conteneurEntity.partenaire ? conteneurEntity.partenaire.id : ''}</dd>
          <dt>
            <Translate contentKey="alexisExportApp.conteneur.devis">Devis</Translate>
          </dt>
          <dd>{conteneurEntity.devis ? conteneurEntity.devis.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/conteneur" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/conteneur/${conteneurEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ConteneurDetail;
