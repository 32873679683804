import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './photo.reducer';

export const PhotoDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const photoEntity = useAppSelector(state => state.photo.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="photoDetailsHeading">
          <Translate contentKey="alexisExportApp.photo.detail.title">Photo</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{photoEntity.id}</dd>
          <dt>
            <span id="intitule">
              <Translate contentKey="alexisExportApp.photo.intitule">Intitule</Translate>
            </span>
          </dt>
          <dd>{photoEntity.intitule}</dd>
          <dt>
            <span id="lienVersFichierPhoto">
              <Translate contentKey="alexisExportApp.photo.lienVersFichierPhoto">Lien Vers Fichier Photo</Translate>
            </span>
          </dt>
          <dd>{photoEntity.lienVersFichierPhoto}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alexisExportApp.photo.description">Description</Translate>
            </span>
          </dt>
          <dd>{photoEntity.description}</dd>
          <dt>
            <span id="author">
              <Translate contentKey="alexisExportApp.photo.author">Author</Translate>
            </span>
          </dt>
          <dd>{photoEntity.author}</dd>
          <dt>
            <span id="proprietaire">
              <Translate contentKey="alexisExportApp.photo.proprietaire">Proprietaire</Translate>
            </span>
          </dt>
          <dd>{photoEntity.proprietaire}</dd>
          <dt>
            <span id="hauteur">
              <Translate contentKey="alexisExportApp.photo.hauteur">Hauteur</Translate>
            </span>
          </dt>
          <dd>{photoEntity.hauteur}</dd>
          <dt>
            <span id="largeur">
              <Translate contentKey="alexisExportApp.photo.largeur">Largeur</Translate>
            </span>
          </dt>
          <dd>{photoEntity.largeur}</dd>
          <dt>
            <span id="datePersistence">
              <Translate contentKey="alexisExportApp.photo.datePersistence">Date Persistence</Translate>
            </span>
          </dt>
          <dd>
            {photoEntity.datePersistence ? <TextFormat value={photoEntity.datePersistence} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="dateUpdate">
              <Translate contentKey="alexisExportApp.photo.dateUpdate">Date Update</Translate>
            </span>
          </dt>
          <dd>{photoEntity.dateUpdate ? <TextFormat value={photoEntity.dateUpdate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alexisExportApp.photo.produit">Produit</Translate>
          </dt>
          <dd>{photoEntity.produit ? photoEntity.produit.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/photo" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/photo/${photoEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PhotoDetail;
