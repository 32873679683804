import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Devis from './devis';
import DevisDetail from './devis-detail';
import DevisUpdate from './devis-update';
import DevisDeleteDialog from './devis-delete-dialog';

const DevisRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Devis />} />
    <Route path="new" element={<DevisUpdate />} />
    <Route path=":id">
      <Route index element={<DevisDetail />} />
      <Route path="edit" element={<DevisUpdate />} />
      <Route path="delete" element={<DevisDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DevisRoutes;
