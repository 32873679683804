import React from 'react';
import CoutNonQualiteChart from 'app/modules/tableau-de-bord/coutNonQualiteChart';
import TauxDefautChart from 'app/modules/tableau-de-bord/tauxDefautChart';

const Qualite = () => {
  const styles: { [key: string]: React.CSSProperties } = {
    responsiveGrid: {
      display: 'flex',
      flexWrap: 'wrap', // Permet la responsivité
      gap: '20px',
      justifyContent: 'space-around',
    },
    gridItem: {
      flexBasis: '30%', // Chaque item prendra 30% de la largeur disponible
      padding: '20px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
    },
    commentHeader: {
      marginBottom: '20px',
      padding: '10px',
      backgroundColor: '#ffcccc', // Light red background to make it noticeable
      borderRadius: '5px',
      color: '#333',
      fontSize: '14px',
      textAlign: 'center',
    } as React.CSSProperties,
  };

  return (
    <div>
      <h2>Tableau de Bord Qualité</h2>
      {/* Comment Header */}
      <div style={styles.commentHeader}>
        Ces valeurs sont pour l'instant fake en attendant les données réelles en provenance de la base de données. Nous avons juste préparé
        ici les éléments nécessaires prêts à être reliés aux vraies données.
      </div>
      <div style={styles.responsiveGrid}>
        {/* Taux de Défaut */}
        <div style={styles.gridItem}>
          <h3>Taux de Défaut</h3>
          <TauxDefautChart />
        </div>

        {/* Coût de la Non-Qualité */}
        <div style={styles.gridItem}>
          <h3>Coût de la Non-Qualité</h3>
          <CoutNonQualiteChart />
        </div>
      </div>
    </div>
  );
};

export default Qualite;
