import React from 'react';
import TauxLivraisonTempsChart from 'app/modules/tableau-de-bord/TauxLivraisonTempsChart';
import TempsCycleCommandeChart from 'app/modules/tableau-de-bord/TempsCycleCommandeChart';
import CoutLogistiqueUniteChart from 'app/modules/tableau-de-bord/CoutLogistiqueUniteChart';

const Logistique = () => {
  const styles: { [key: string]: React.CSSProperties } = {
    responsiveGrid: {
      display: 'flex',
      flexWrap: 'wrap', // Correction ici, utilisation de 'wrap'
      gap: '20px',
      justifyContent: 'space-around',
    },
    gridItem: {
      flexBasis: '30%',
      padding: '20px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
    },
    commentHeader: {
      marginBottom: '20px',
      padding: '10px',
      backgroundColor: '#ffcccc', // Light red background to make it noticeable
      borderRadius: '5px',
      color: '#333',
      fontSize: '14px',
      textAlign: 'center',
    } as React.CSSProperties,
  };

  return (
    <div>
      <h2>Tableau de Bord Logistique</h2>

      {/* Comment Header */}
      <div style={styles.commentHeader}>
        Ces valeurs sont pour l'instant fake en attendant les données réelles en provenance de la base de données. Nous avons juste préparé
        ici les éléments nécessaires prêts à être reliés aux vraies données.
      </div>
      <div style={styles.responsiveGrid}>
        {/* Taux de Livraison à Temps */}
        <div style={styles.gridItem}>
          <h3>Taux de Livraison à Temps</h3>
          <TauxLivraisonTempsChart />
        </div>

        {/* Coût Logistique par Unité Expédiée */}
        <div style={styles.gridItem}>
          <h3>Coût Logistique par Unité Expédiée</h3>
          <CoutLogistiqueUniteChart />
        </div>

        {/* Temps de Cycle de Commande */}
        <div style={styles.gridItem}>
          <h3>Temps de Cycle de Commande</h3>
          <TempsCycleCommandeChart />
        </div>
      </div>
    </div>
  );
};

export default Logistique;
