import React from 'react';
import { translate } from 'react-jhipster';
import { NavDropdown } from 'app/shared/layout/menus/menu-components';
import TableauDeBordMenuItems from 'app/modules/tableau-de-bord/menu'; // Importez le fichier que vous venez de créer

export const TableauDeBordMenu = ({ onClick }) => (
  <NavDropdown
    icon="tachometer-alt" // Icône du menu principal
    name={translate('global.menu.tableaudeBord.main')} // Nom de l'onglet
    id="tableau-de-bord-menu"
    data-cy="tableauDeBord"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <TableauDeBordMenuItems onClick={onClick} />
  </NavDropdown>
);
