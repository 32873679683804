import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Translate, TextFormat, getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Storage } from 'react-jhipster';
import { getEntities } from './devis.reducer';

export const Devis = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const devisList = useAppSelector(state => state.devis.entities);
  const loading = useAppSelector(state => state.devis.loading);
  const totalItems = useAppSelector(state => state.devis.totalItems);

  const [modal, setModal] = useState(false);
  const [selectedDevisId, setSelectedDevisId] = useState('');
  const [selectedLogo, setSelectedLogo] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmOverwrite, setConfirmOverwrite] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const toggleModal = () => setModal(!modal);

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (validTypes.includes(file.type)) {
        setSelectedLogo(file);
      } else {
        alert('Veuillez choisir un fichier image valide (JPEG, PNG, GIF).');
      }
    }
  };

  const generateInvoice = async (formData: FormData) => {
    const jwtToken = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');

    if (!jwtToken) {
      alert('JWT Token non trouvé. Veuillez vous authentifier.');
      return;
    }

    try {
      const response = await fetch(`/api/invoices/generate?idDevis=${selectedDevisId}`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      return response;
    } catch (error) {
      console.error('Erreur lors de la génération de la facture', error);
      alert('Erreur lors de la génération de la facture : ' + error.message);
      return null;
    }
  };

  const handleGenerateInvoice = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!selectedDevisId || !selectedLogo) {
      alert("Veuillez renseigner l'ID du devis et choisir un logo pour générer la facture.");
      return;
    }

    const formData = new FormData();
    formData.append('logo', selectedLogo);

    const response = await generateInvoice(formData);

    if (!response) return;

    const message = await response.text();

    // if (response.status === 409) {
    if (message === 'EXISTS_CONFIRM_OVERWRITE') {
      setIsModalOpen(true); // Ouvrir le modal de confirmation
      const userConfirmed = await new Promise<boolean>(resolve => {
        const confirmButton = document.getElementById('confirmOverwriteButton');
        const cancelButton = document.getElementById('cancelOverwriteButton');

        confirmButton?.addEventListener('click', () => resolve(true));
        cancelButton?.addEventListener('click', () => resolve(false));
      });

      setIsModalOpen(false); // Fermer le modal de confirmation

      // Créer un blob à partir de la réponse
      //    const blob = await response.blob();
      //  const url = window.URL.createObjectURL(blob);

      if (userConfirmed) {
        formData.append('confirmOverwrite', 'true');
        await generateInvoice(formData); // Relancer la génération avec l'écrasement confirmé
      }
    } else if (response.ok) {
      alert('PDF généré avec succès.');
      toggleModal();
      // Ouvrir le PDF dans un nouvel onglet
      // Ouvrir le PDF généré
      window.open(`/content/factures/facture_numero_${selectedDevisId}.pdf`);
    } else {
      console.error('Erreur lors de la génération de la facture :', message);
      alert('Erreur lors de la génération de la facture : ' + message);
    }
  };

  const handleConfirmOverwrite = async () => {
    setIsModalOpen(false);

    const formData = new FormData();
    formData.append('logo', selectedLogo!);
    formData.append('confirmOverwrite', 'true');

    const response = await generateInvoice(formData);

    if (response && response.ok) {
      alert('PDF écrasé et généré avec succès.');
      toggleModal();
      // Ouvrir le PDF dans un nouvel onglet
      window.open(`/content/factures/facture_numero_${selectedDevisId}.pdf`);
    }
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage => setPaginationState({ ...paginationState, activePage: currentPage });

  const handleSyncList = () => sortEntities();

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="devis-heading" data-cy="DevisHeading">
        <Translate contentKey="alexisExportApp.devis.home.title">Devis</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="alexisExportApp.devis.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/devis/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="alexisExportApp.devis.home.createLabel">Create new Devis</Translate>
          </Link>
          <Button color="secondary" className="ms-2">
            Générer un Devis (pdf)
          </Button>
          <Button color="secondary" onClick={toggleModal} className="ms-2">
            Générer une Facture (pdf)
          </Button>
        </div>
      </h2>

      {/* Modal for generating invoice */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Générer une Facture</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="devisId">ID du Devis</Label>
              <Input
                type="text"
                id="devisId"
                value={selectedDevisId}
                onChange={e => setSelectedDevisId(e.target.value)}
                placeholder="Saisissez l'ID du devis"
              />
            </FormGroup>
            <FormGroup>
              <Label for="logo">Choisir un logo</Label>
              <Input type="file" id="logo" onChange={handleLogoChange} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleGenerateInvoice}>
            Générer/imprimer
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>

      {/* Confirmation modal for overwriting */}
      <Modal isOpen={isModalOpen} onRequestClose={handleModalClose}>
        <h2>Confirmation de l'Écrasement</h2>
        <p>Un fichier avec ce nom existe déjà. Voulez-vous l'écraser ?</p>
        <button id="confirmOverwriteButton" color="primary" onClick={handleConfirmOverwrite}>
          Oui, écraser
        </button>
        <button id="cancelOverwriteButton" color="secondary" onClick={handleModalClose}>
          Non, annuler
        </button>
      </Modal>

      <div className="table-responsive">
        {devisList && devisList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alexisExportApp.devis.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('montantTotal')}>
                  <Translate contentKey="alexisExportApp.devis.montantTotal">Montant Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('montantTotal')} />
                </th>
                <th className="hand" onClick={sort('prixDeTransport')}>
                  <Translate contentKey="alexisExportApp.devis.prixDeTransport">Prix De Transport</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('prixDeTransport')} />
                </th>
                <th className="hand" onClick={sort('isPayedIsFacture')}>
                  <Translate contentKey="alexisExportApp.devis.isPayedIsFacture">Is Payed Is Facture</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isPayedIsFacture')} />
                </th>
                <th className="hand" onClick={sort('lienVersDevisPdf')}>
                  <Translate contentKey="alexisExportApp.devis.lienVersDevisPdf">Lien Vers Devis Pdf</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lienVersDevisPdf')} />
                </th>
                <th className="hand" onClick={sort('datePersistence')}>
                  <Translate contentKey="alexisExportApp.devis.datePersistence">Date Persistence</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('datePersistence')} />
                </th>
                <th className="hand" onClick={sort('dateUpdate')}>
                  <Translate contentKey="alexisExportApp.devis.dateUpdate">Date Update</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('dateUpdate')} />
                </th>
                <th>
                  <Translate contentKey="alexisExportApp.devis.client">Client</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {devisList.map((devis, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/devis/${devis.id}`} color="link" size="sm">
                      {devis.id}
                    </Button>
                  </td>
                  <td>{devis.montantTotal}</td>
                  <td>{devis.prixDeTransport}</td>
                  <td>{devis.isPayedIsFacture ? 'true' : 'false'}</td>
                  <td>{devis.lienVersDevisPdf}</td>
                  <td>
                    {devis.datePersistence ? <TextFormat type="date" value={devis.datePersistence} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{devis.dateUpdate ? <TextFormat type="date" value={devis.dateUpdate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{devis.client ? <Link to={`/client/${devis.client.id}`}>{devis.client.id}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/devis/${devis.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/devis/${devis.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/devis/${devis.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alexisExportApp.devis.home.notFound">No Devis found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={devisList && devisList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Devis;
